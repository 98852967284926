@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  position: absolute;
	background-color: #0A192F;
}

@media screen and (max-width: 767px) {
	html {
		overflow-x: hidden;
	}
  body {
    overflow-x: hidden;
  }
}
